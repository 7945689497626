var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-card",
                { attrs: { elevation: "10" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { "z-index": "9" },
                      attrs: {
                        color: "#003d6a",
                        dark: "",
                        dense: "",
                        elevation: "10",
                      },
                    },
                    [
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.pageTitle))]),
                      _c("v-spacer"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchFilterInput,
                            expression: "searchFilterInput",
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { "max-width": "250px" },
                        attrs: {
                          type: "text",
                          autocomplete: "new",
                          placeholder: _vm.$t("search_t"),
                        },
                        domProps: { value: _vm.searchFilterInput },
                        on: {
                          keyup: function ($event) {
                            return _vm.onFilterTextBoxChanged()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchFilterInput = $event.target.value
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.reservationGridShow
                    ? _c(
                        "CCardBody",
                        { staticClass: "p-0" },
                        [
                          _c("ag-grid-vue", {
                            class: _vm.getClasses(),
                            staticStyle: {
                              width: "100%",
                              height: "calc(100vh - 145px)",
                            },
                            attrs: {
                              defaultExcelExportParams:
                                _vm.defaultExcelExportParams,
                              columnDefs: _vm.columnDefs,
                              defaultColDef: _vm.defaultColDef,
                              rowData: _vm.rowData,
                              rowSelection: _vm.rowSelection,
                              sideBar: _vm.sideBar,
                              enableCharts: true,
                              enableRangeSelection: true,
                              enableBrowserTooltips: true,
                              statusBar: _vm.statusBar,
                              animateRows: false,
                              getContextMenuItems: _vm.getContextMenuItems,
                            },
                            on: {
                              "column-moved": _vm.onColumnMoved,
                              "column-pinned": _vm.onColumnMoved,
                              "column-visible": _vm.onColumnMoved,
                              "column-resized": _vm.onColumnMoved,
                              "column-changed": _vm.onColumnMoved,
                              "column-row-group-changed": _vm.onColumnMoved,
                              rowDoubleClicked: _vm.onRowDoubleClicked,
                              "grid-ready": _vm.onGridReady,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "CCardBody",
                        { staticClass: "p-0" },
                        [_c("gogo-loading")],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }